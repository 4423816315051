import http from "@/plugins/https";

const api = {
  page: '/baseCwse/page',
  list: '/baseCwse/list',
  areaList: '/baseArea/list',
  areaTreeList: '/baseArea/getTree',
  menuList: '/baseMenu/list',
  menuAll: '/baseMenu/get',
  menuAdd: '/baseMenu/add',
  menuUpdate: '/baseMenu/update',
  menuDelete: '/baseMenu/delete',
  noticeList: '/baseNotice/list',
  noticeInfo: '/baseNotice/get',
  noticeAdd: '/baseNotice/add',
  noticeUpdate: '/baseNotice/update',
  noticeDelete: '/baseNotice/delete',
  docList: '/baseDoc/list',
  docInfo: '/baseDoc/get',
  docAdd: '/baseDoc/add',
  docUpdate: '/baseDoc/update',
  docDelete: '/baseDoc/delete',
  docDetailList: '/baseDoc/detailList',
  uploadUrl: '/baseFile/upload'

}

export default api

export function getProjectPage (parameter) {
  return http({
    url: api.page,
    method: 'get',
    params: parameter
  })
}

export function getProjectList (parameter) {
  return http({
    url: api.list,
    method: 'get',
    params: parameter
  })
}

export function getAreaList (parameter) {
  return http({
    url: api.areaList,
    method: 'get',
    params: parameter
  })
}

export function getAreaTreeList (parameter) {
  return http({
    url: api.areaTreeList,
    method: 'get',
    params: parameter
  })
}

export function getMenuList (parameter) {
  return http({
    url: api.menuList,
    method: 'get',
    params: parameter
  })
}

export function getOrgTree (parameter) {
  return http({
    url: api.orgTree,
    method: 'get',
    params: parameter
  })
}

export function getMenuAll (parameter) {
  return http({
    url: api.menuAll,
    method: 'get',
    params: parameter
  })
}

export function updataMenu (parameter) {
  return http({
    url: api.menuUpdate,
    method: 'post',
    data: parameter
  })
}

export function addMenu (parameter) {
  return http({
    url: api.menuAdd,
    method: 'post',
    data: parameter
  })
}

export function deleteMenu (parameter) {
  return http({
    url: api.menuDelete,
    method: 'get',
    params: parameter
  })
}

export function getNoticeList (parameter) {
  return http({
    url: api.noticeList,
    method: 'get',
    params: parameter
  })
}

export function getNoticeInfo (parameter) {
  return http({
    url: api.noticeInfo,
    method: 'get',
    params: parameter
  })
}

export function addNotice (parameter) {
  return http({
    url: api.noticeAdd,
    method: 'post',
    data: parameter
  })
}

export function updateNotice (parameter) {
  return http({
    url: api.noticeUpdate,
    method: 'post',
    data: parameter
  })
}

export function deleteNotice (parameter) {
  return http({
    url: api.noticeDelete,
    method: 'get',
    params: parameter
  })
}

export function getDocList (parameter) {
  return http({
    url: api.docList,
    method: 'get',
    params: parameter
  })
}
export function getDocDetailList (parameter) {
  return http({
    url: api.docDetailList,
    method: 'get',
    params: parameter
  })
}

export function getDocInfo (parameter) {
  return http({
    url: api.docInfo,
    method: 'get',
    params: parameter
  })
}

export function addDoc (parameter) {
  return http({
    url: api.docAdd,
    method: 'post',
    data: parameter
  })
}

export function updateDoc (parameter) {
  return http({
    url: api.docUpdate,
    method: 'post',
    data: parameter
  })
}

export function deleteDoc (parameter) {
  return http({
    url: api.docDelete,
    method: 'get',
    params: parameter
  })
}

export function upload (parameter) {
  return http({
    url: api.uploadUrl,
    method: 'post',
    data: parameter
  })
}
